//=require ../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../node_modules/swiper/dist/js/swiper.min.js
//=require ../../node_modules/lightbox2/dist/js/lightbox.min.js
//=require ../../node_modules/waypoints/lib/jquery.waypoints.min.js
//=require ../../node_modules/headroom.js/dist/headroom.min.js
//=require ../../node_modules/headroom.js/dist/jQuery.headroom.min.js
//=require ../../node_modules/mobile-detect/mobile-detect.min.js

/* jshint -W030 */
!function($, window) {
    "use strict";

    // Vars
    var $doc = $(document), $win = $(window), SITE = SITE || {};

    SITE = {
        // Init
        init: function() {
            var obj, self = this;
            for (obj in self) if (self.hasOwnProperty(obj)) {
                var _method = self[obj];
                void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
            }
        },

        // mobile detect
        Mobiledetect: {
            selector: "body",
            init: function() {
                var base = this, container = $(base.selector);
                var md = new MobileDetect(window.navigator.userAgent);

                if(md.mobile() !== null) {
                  $("body").addClass("mobile");
                }
            }
        },

        // Fixed navigation
        NavScrolled: {
            selector: ".main-header",
            init: function() {
                var base = this, container = $(base.selector);

                container.headroom({
                  "offset": 90,
                  "tolerance": {
                    down : 10,
                    up : 20
                  },
                  onUnpin : function() {

                  },
                });
            }
        },

        //Multi Level Menu
        MultiLevelMenu: {
            selector: ".menu-wrapper",
            init: function() {
                var base = this, container = $(base.selector);

                var el = $(".menu-wrapper ul li a");
                var elActive = $(".menu-wrapper > ul").find("a.active");

                elActive.parent("li").addClass("active");
                elActive.parent("li").parents("li").addClass("active-trail");

                if($(".menu-wrapper ul ul").length) {
                  $(".menu-wrapper > ul").addClass("sublvl");
                }

                el.each(function() {
                  if($(this).next("ul").length) {
                    $(this).after('<span class="expand"></span>');
                  }
                });

                $(".menu-wrapper ul li .expand").on("click", function() {
                  $(this).parent("li").toggleClass('open');
                });
            }
        },

        // Lang menu
        LangMenu: {
            selector: "ul.language-switcher-locale-url",
            init: function () {
                var base = this, container = $(base.selector), activeLang = container.find('li.active a').text();

                // create span with active lang
                container.parent().prepend('<span class="label">'+activeLang+'<i class="fa fa-angle-down" aria-hidden="true"></i></span>');
                // hide  active lang in ul
                container.find('li.active').remove();

                //show ul
                container.parent().find('.label').on('click', function (e){
                    e.stopPropagation();
                    container.slideToggle(300);
                    $(this).find('.fa').toggleClass('fa-caret-down');
                    $(this).find('.fa').toggleClass('fa-caret-up');
                });

                //hide ul
                $(document).on('click', function(e) {
                    var $clicked = $(e.target);
                    if (!$clicked.parents().hasClass("lang")) {
                        container.slideUp(300);
                        container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                        container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                    }
                });

                $(document).scroll(function() {
                    if ($(document).scrollTop() >= 90) {
                        container.slideUp(300);
                        container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                        container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                    }
                });
            }
        },

        // Burger menu
        BurgerMenu: {
            selector: ".trigger-menu",
            init: function () {
                var base = this, container = $(base.selector);
                $(document).on('click', base.selector, function(e) {
                    e.preventDefault();
                    $(this).toggleClass('open');
                    $('body').toggleClass('open-menu');
                });
            }
        },

        //Data to BG
        DataToBg: {
            selector: "*[data-bg]",
            init: function() {
                var base = this, container = $(base.selector);

                container.each(function() {
                    var bg = $(this).data('bg');
                    if (bg.substr(0, 1) === '#') {
                        $(this).css({
                            'background-color': bg
                        });
                    }else{
                        $(this).css({
                            'background-image': 'url('+bg+')'
                        });
                    }
                });
            }
        },

        // on scroll animation
        OsAnimation: {
            init: function () {
                function onScrollInit( items, trigger ) {
                    items.each( function() {
                        var osElement = $(this),
                            osAnimationClass = osElement.attr('data-os-animation'),
                            osAnimationDelay = osElement.attr('data-os-animation-delay');

                        osElement.css({
                            '-webkit-animation-delay':  osAnimationDelay,
                            '-moz-animation-delay':     osAnimationDelay,
                            'animation-delay':          osAnimationDelay
                        });

                        var osTrigger = ( trigger ) ? trigger : osElement;

                        osTrigger.waypoint(function() {
                            osElement.addClass('animated').addClass(osAnimationClass);
                        },{
                            triggerOnce: true,
                            offset: '90%'
                        });
                    });
                }

                onScrollInit( $('.os-animation') );
                $('.staggered-animation-container').each(function() {
                    onScrollInit( $(this).find('.staggered-animation'), $(this) );
                });
            }
        },


        // Swiper gallery
        ContentGallery: {
            selector: ".paragraphs-galerie .swiper-container",
            init: function () {
                var base = this, container = $(base.selector);

                var mySwiper = new Swiper(container, {
                  effect: 'fade',
                  loop: true,
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  pagination: {
                    el: '.swiper-pagination',
                  },
                  autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                  },
                });
            }
        },

        // Close messages
        MessagesClose: {
            selector: ".messages",
            init: function () {
                var base = this, container = $(base.selector);
                container.prepend('<a href="#" class="close">×</a>');

                $(document).on("click", ".messages .close", function(e) {
                    e.preventDefault();
                    $(this).parents(".messages").slideUp(200);
                });
            }
        },

        // Vimeo
        /*vimeoPlayer: {
          selector: ".vimeo.video-background iframe",
          init: function () {
            var base = this, container = $(base.selector);

            // Load API script
            $.getScript("https://player.vimeo.com/api/player.js")
              .done(function() {
                var player = new Vimeo.Player(container);
                player.on('play', function() {
                  container.addClass('active');
                  SITE.vidRescale.resize();
                });
              })
              .fail(function() {
                console.log("Error loading Vimeo API script");
              });
          }
        },*/
        // Video resize
        /*vidRescale: {
          selector: ".video-background iframe",
          resize: function () {
            var base = this, container = $(base.selector);
            var w = Math.round($win.width() + 200), h = Math.round($win.height() + 200), wResize = Math.round(w / 16 * 9), hResize = Math.round(h / 9 * 16), leftPosition = Math.round(-(container.outerWidth() - w) / 2);
            if ((w / h) > (16 / 9)) {
              container.width(w).height(wResize).css({'left': '0px'});
            } else {
              container.width(hResize).height(h).css({'left': leftPosition});
            }
          }
        },*/

        // YouTube
        youtubePlayer: {
          selector: ".youtube.video-background #ytplayer",
          init: function () {
            var base = this, container = $(base.selector);
            // Load API script
            if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
              $.getScript('//www.youtube.com/iframe_api')
                .fail(function() {
                  console.log("Error loading YouTube API script");
                });
              window.onYouTubeIframeAPIReady = function() {
                base.loadPlayer();
              };
            } else {
              base.loadPlayer();
            }
          },
          loadPlayer: function () {
            var base = this, videoID = Drupal.settings.bgvideo.id;
            window.player = new YT.Player('ytplayer', {
              videoId: videoID,
              playerVars: {
                playlist: videoID,
                autoplay: 1,        // Auto-play the video on load
                controls: 0,        // Show pause/play buttons in player
                showinfo: 0,        // Hide the video title
                modestbranding: 1,  // Hide the Youtube Logo
                loop: 1,            // Run the video in a loop
                fs: 0,              // Hide the full screen button
                cc_load_policy: 0,  // Hide closed captions
                iv_load_policy: 3,  // Hide the Video Annotations
                autohide: 1         // Hide video controls when playing
              },
              events: {
                onReady: function(e) {
                  e.target.mute();
                  SITE.vidRescale.resize();
                },
                onStateChange: function(e) {
                  if (e.data === 1) {
                    $('.youtube.video-background iframe').addClass('active');
                  }
                }
              }
            });
          }
        },

        // Video resize
        vidRescale: {
          selector: ".video-background iframe",
          resize: function () {
            var base = this, container = $(base.selector), videoType = Drupal.settings.bgvideo.type, player = window.player;
            var w = Math.round($win.width() + 200), h = Math.round($win.height() + 200), wResize = Math.round(w / 16 * 9), hResize = Math.round(h / 9 * 16), leftPosition = Math.round(-(container.outerWidth() - w) / 2);

            if(leftPosition > 0) {
              leftPosition = 0;
            }

            if ((w / h) > (16 / 9)) {
              if(videoType == "youtube" && player != undefined) {
                player.setSize(w, wResize);
              }
              container.width(w).height(wResize).css({'left': '0px'});
            } else {
              if(videoType == "youtube" && player != undefined) {
                player.setSize(hResize, h);
                leftPosition = Math.round(-(container.outerWidth() - w) / 2);
              }
              container.width(hResize).height(h).css({'left': leftPosition});
            }
          }
        },


        // Swiper steps
        steps: {
            selector: ".steps .swiper-container",
            init: function () {
                var base = this, container = $(base.selector);

                var mySwiper = new Swiper(container, {
                  slidesPerView: 2,
                  loop: true,
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  pagination: {
                    el: '.swiper-pagination',
                  },
                  autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                  },
                  breakpoints: {
                    // when window width is <= 320px
                    991: {
                      slidesPerView: 1
                    }
                  }
                });
            }
        },


        // categorie
        categorie: {
            selector: ".menu-catalogue > h2",
            init: function () {
                var base = this, container = $(base.selector);

                container.on("click", function() {
                  $(this).toggleClass('show');
                  $(this).next("ul").slideToggle(300);
                });
            }
        },

        // cta fixed
        ctaFixed: {
            selector: ".cta-fixed a",
            init: function () {
                var base = this, container = $(base.selector);

                $(window).scroll(function() {
                  if ($(document).scrollTop() > 90) {
                    container.addClass("scrolled");
                  } else {
                    container.removeClass("scrolled");
                  }
                });
            }
        },

        // Smooth scroll anchor
        smoothScroll: {
          selector: "body",
          init: function() {
            var base = this, container = $(base.selector);

            $('a[href*="#"]:not([href="#"]):not(.collapsed):not([data-toggle="modal"])').click(function() {
              if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                  $('html, body').animate({
                    scrollTop: target.offset().top - 90
                  }, 1000);
                  return false;
                }
              }
            });
          }
        },




        // Resize
    }, $win.resize(function() {
        if($(SITE.vidRescale.selector).length > 0) {
          SITE.vidRescale.resize();
        }

        // Scroll
    }), $win.scroll(function() {

        // Ready
    }), $doc.ready(function() {
        SITE.init();
    });
}(jQuery, this);
